import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useState, useEffect } from "react";
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, Box } from '@mui/material';
// utils
import { findNextMultipleOf1000, fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------



function AppTypes(props) {
  const CHART_DATATypes = [
    {
      data:
        props.icpc_consultation.icpc_counts &&
        props.icpc_consultation.icpc_counts.map((count) => parseInt(count)),
    },
  ];
  const [prescXaxis, setPrescXaxis] = useState(0);
  const [refreshGraph, setRefreshGraph] = useState(false);

  useEffect(() => {
    if (props.icpc_consultation && props.icpc_consultation !== undefined) {
      setRefreshGraph(prev => !prev);
    }
  }, [props.icpc_consultation]);

  useEffect(() => {
    if (props.icpc_consultation && props.icpc_consultation.icpc_counts) {
      const maxVal = Math.max(...props.icpc_consultation.icpc_counts);
      const maxX = findNextMultipleOf1000(maxVal + ( maxVal * 0.2));
      setPrescXaxis(maxX);
    }
  }, [props.icpc_consultation]);
  const chartOptions_TypesBar = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      followCursor: true,
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (val, opt) {
          const total = CHART_DATATypes[0].data.reduce((acc, curr) => acc + curr, 0);
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        title: {
          formatter: (seriesName) => `Percentage of all consultations: `
        }     
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetX: 35,
    },
    xaxis: {
      categories: props.icpc_consultation.icpc_descriptions
        ? props.icpc_consultation.icpc_descriptions.map((desc) => {
            if (desc.toLowerCase() === 'copd') {
              return 'COPD';
            }
            return desc.charAt(0).toUpperCase() + desc.slice(1);
          })
        : null,
      show: true,
      min: 0,
      max: prescXaxis,
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          colors: "#808080",
        },
      },
      // position: 'top',
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    stroke: {
      show: false,
    },
  });


  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Box sx={{ mx: 1 }} dir="ltr">
        <ReactApexChart
          type="bar"
          series={CHART_DATATypes}
          options={chartOptions_TypesBar}
          height={364}
          key={refreshGraph}
        />
      </Box>
    </Card>
  );
}
import { connect } from "react-redux";
const mapState = state => ({
  icpc_consultation: state.overview.icpc_consultation
 });
 
 const mapDispatch = ({ 
  overview: {loadData}}) => ({
    loadData: () => loadData()
 });
 
 export default connect(mapState, mapDispatch)(AppTypes);
