// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Paper,
  Stack,
  Button,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppConsultTotal,
  AppActivePatients,
  AppPrescriptionsTotal,
  AppPractice1,
  AppPractice2,
  Appmale_femaleDonut,
  Appmale_femaleBar,
  AppAgesDonut,
  AppAgesBar,
  AppTypesDonut,
  AppTypesBar,
  AppConsultationsLine,
  AppConsultationsBar1,
} from "../components/_dashboard/practiceOverview";

// ----------------------------------------------------------------------

export default function ProductList() {
  return (
    <Page
      title="Practiceoverview"
      sx={{ backgroundColor: "#fff", marginLeft: "100%/6" }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={12}>
          <Grid item xs={12} sm={6} md={12}>
            <Typography variant="h2" color="secondary" sx={{ marginTop: "6%" }}>
              Practice Overview
            </Typography>
            <Typography variant="subtitle1" color="secondary">
              Graphs display data for selected period.
            </Typography>
            <br />
            <Button variant="contained" href="">
              Upload new data
            </Button>
            <br></br>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppPrescriptionsTotal />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppActivePatients />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <AppConsultTotal />
          </Grid>
        </Grid>
        <Grid container spacing={4} sx={{ borderRadius: 0 }}>
          <Grid item xs={12} md={12} lg={12} id="prescriptions">
            <br />
            <br />
            <Typography variant="h4" color="#22577A">
              Prescriptions in your practice
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} lg={6}>
            <AppPractice1 />
          </Grid>

          <Grid item xs={12} md={12} lg={6}>
            <AppPractice2 />
          </Grid>

          <Grid item xs={12} md={12} lg={12} id="demographics">
            <br />
            <br />
            <Typography variant="h4" color="#22577A">
              Patients in your practice
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" color="#22577A">
              Gender
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <Appmale_femaleDonut />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Appmale_femaleBar />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" color="#22577A">
              Age
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppAgesDonut />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <AppAgesBar />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" color="#22577A">
              Type
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <AppTypesDonut />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <AppTypesBar />
          </Grid>

          <Grid item xs={12} md={6} lg={12} id="consultations">
            <br />
            <br />
            <Typography variant="h4" color="#22577A">
              Consultations in your practice
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <AppConsultationsLine />
          </Grid>

          <Grid item xs={12} md={6} lg={7}>
            <Typography variant="h6" color="#22577A">
            Consultations for Chronic Diseases (ICPC)
            </Typography>
            <AppConsultationsBar1 />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
