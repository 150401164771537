import * as React from 'react';
import { useRef, useState, useEffect } from 'react';

// material
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton, Stack, Grid } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
import { connect } from "react-redux";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { set } from 'lodash';
import { matchPath, useLocation } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const popover = function AccountPopover(props) {

  const { pathname } = useLocation();
  const [match, setMatch] = useState([]);

  useEffect(async () => {
    setMatch("/dashboard/antibiotics/comparison"===pathname);
  }, [pathname]);

  useEffect(async () => {
    await props.updateDateFilters();
  }, []);

  const ageOptions = [
    '<1',
    '1-6',
    '7-17',
    '18-29',
    '30-49',
    '50-69',
    '>=70'
  ];
  const typeOptions = [
    'GMS',
    'Private'
  ];
  const genderOptions = [
    'Male',
    'Female'
  ];
  const [value, setValue] = React.useState(new Date('2022-04-17'));
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const prevSelections = [];
  const [ageRange, setAgeRange] = React.useState([]);
  const [first, setFirst] = React.useState(null);
  const [second, setSecond] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);

  // Function to handle radio button click
  const handleRadioClick = (option, filterName, index) => {
    // Toggle the selected option
    let updatedSelectedOptions = [];
    // Update combinedFilters directly by modifying the relevant filter array
    switch (filterName) {
      case "ageGroupsFilters":
        updatedSelectedOptions = props.selectedOptions.includes(option)
          ? props.selectedOptions.filter((item) => item !== option)
          : [...props.selectedOptions, option];
        const ageRange = ageGroupsFilters.filter((item) => item[0] !== option)
        if (props.selectedOptions.includes(option)) {
          props.updateFilter({ key: 'age', value: ageRange });
          updateFilters();
        }
        setAgeGroupsFilters(ageRange);
        break;
      case "genderFilters":
        updatedSelectedOptions = null;
        updatedSelectedOptions = props.selectedOptions.includes(option)
          ? props.selectedOptions.filter((item) => item !== option)
          : [...props.selectedOptions, option];
        const gender = genderFilters.filter((item) => item !== option)

        if (props.selectedOptions.includes(option)) {
          props.updateFilter({ key: 'gender', value: gender });
          updateFilters();
        }
        setGenderFilters(gender);

        break;
      case "typeFilters":
        updatedSelectedOptions = [];
        updatedSelectedOptions = props.selectedOptions.includes(option)
          ? props.selectedOptions.filter((item) => item !== option)
          : [...props.selectedOptions, option];
        const gms = typeFilters.filter((item) => item[0] !== option)
        if (props.selectedOptions.includes(option)) {
          props.updateFilter({ key: 'gms', value: gms });
          updateFilters();
        }
        setTypeFilters(gms);

        break;
      default:
        break;
    }


    props.setSelectedOptions(updatedSelectedOptions);
  };



  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleClose = () => {
  //   onClose();
  // };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const [ageGroupsFilters, setAgeGroupsFilters] = React.useState(props.filters.age || []);
  const [genderFilters, setGenderFilters] = React.useState(props.filters.gender || []);
  const [typeFilters, setTypeFilters] = React.useState(props.filters.gms || []);
  const [dateStart, setDateStart] = React.useState(props.userData.previousYear);
  const [dateEnd, setDateEnd] = React.useState(props.userData.lastUpload);


  const last5years = new Date(dateEnd);
  last5years.setFullYear(last5years.getFullYear() - 5)

  useEffect(() => {
    setAgeGroupsFilters(props.filters.age);
    setGenderFilters(props.filters.gender);
    setTypeFilters(props.filters.gms);
    setDateStart(dateStart);
    setDateEnd(dateStart);
  }, []);

  useEffect(() => {
    setAgeGroupsFilters(props.filters.age);
    setGenderFilters(props.filters.gender);
    setTypeFilters(props.filters.gms);
    setDateStart(props.filters.dateStart);
    setDateEnd(props.filters.dateEnd);
  }, [props.filters.age, props.filters.gender, props.filters.gms, props.filters.dateStart, props.filters.dateEnd]);

  const clearFilters = async () => {
    props.setSelectedOptions([]);
    setAgeGroupsFilters([]);
    setGenderFilters([]);
    setTypeFilters([]);
    setDateStart(props.userData.previousYear);
    setDateEnd(props.userData.lastUpload);
    await props.resetFilters();
    props.updateDateFilters();
    updateFilters();

  }
  //}


  const handleChange = (event) => {

    let value, name;

    // Check if event.target exists, otherwise fall back to event.currentTarget
    if (event.target) {
      value = event.target.value;
      name = event.target.name;
    } else {
      value = event.value;
      name = event.name;
    }

    // Convert string value to Date object for date fields
    if (name === "dateStart" || name === "dateEnd") {
      value = new Date(value);
    } else if (typeof value === 'string') {
      // Convert comma-separated string to array for other filters
      value = value.split(',');
    }

    let updatedSelectedOptions = []
    switch (name) {
      case "ageGroupsFilters":
        updatedSelectedOptions = ageGroupsFilters.includes(value)
          ? ageGroupsFilters.filter((item) => item !== value)
          : [...ageGroupsFilters, value];
        setAgeGroupsFilters(updatedSelectedOptions);
        props.updateFilter({ key: 'age', value: updatedSelectedOptions });
        break;
      case "genderFilters":
        updatedSelectedOptions = null
        updatedSelectedOptions = genderFilters;
        updatedSelectedOptions = genderFilters.includes(value)
          ? genderFilters.filter((item) => item != value)
          : [...genderFilters, ...value];
        setGenderFilters(updatedSelectedOptions);
        props.updateFilter({ key: "gender", value: updatedSelectedOptions });
        break;
      case "typeFilters":
        updatedSelectedOptions = []
        updatedSelectedOptions = typeFilters.includes(value)
          ? typeFilters.filter((item) => item !== value)
          : [...typeFilters, value];
        setTypeFilters(updatedSelectedOptions);
        props.updateFilter({ key: "gms", value: updatedSelectedOptions });
        break;
      case "dateStart":
        setDateStart(value);
        props.updateFilter({ key: "dateStart", value: value });
        break;
      case "dateEnd":
        setDateEnd(value);
        props.updateFilter({ key: "dateEnd", value: value });
        break;

    }
    updateFilters();
  }
  let combinedFilters = [];
  combinedFilters = combinedFilters.concat(
    ageGroupsFilters,
    genderFilters,
    typeFilters,
    dateStart ? [moment(dateStart).format("[Start at 1st] MMMM YYYY")] : [],
    dateEnd ? [moment(dateEnd).format("[End at 1st] MMMM YYYY")] : []
  );

  useEffect(() => {
    props.setAgeGroupsFilters(ageGroupsFilters);
    props.setTypeFilters(typeFilters);
    props.setGenderFilters(genderFilters);
    props.setDateStart(dateStart);
    props.setDateEnd(dateEnd);
    props.setCombinedFilters(combinedFilters);
    props.setSelectedOptions(props.selectedOptions)
  }, [dateStart, dateEnd, genderFilters, typeFilters, ageGroupsFilters,props.selectedOptions]);

  useEffect(() => {
    props.updateDateFilters();
  }, []);

  let updateFilters = async () => {
    await props.getGeneralAB()
    props.promice_8() 
    props.promice_2()
    props.promice_3_4()
    props.promice_5_6()
    props.promice_7()
    props.getPromise_1()
    props.getPromise_2()
    props.getPromise_3()
    props.getPromise_4()
    props.getPromise_5()
    props.getPromise_6()
    props.getPromise_7()
    props.loadData()
    props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4();
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();
    //props.getComparisonsAB()
  }

  return (


    <MenuPopover
      open={props.isOpen}
      onClose={props.onClose}
      onChange={
        () => { updateFilters() }
      }
      sx={{ width: "80%" }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: props.fullExpand ? 60 : 138, left: 300 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{ padding: '20px' }}
      >


      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        {props.fullExpand && <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={2}>
            <Typography>Filters:</Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {combinedFilters.length > 0 ? (
                  combinedFilters.map((value) => (
                    <Chip key={value} label={value} />
                  ))
                ) : (
                  <Typography>No filters Selected</Typography>
                )}
              </Box>
              <Box sx={{marginRight: '12%'}}><Button variant="outlined" onClick={() => clearFilters()} disabled={combinedFilters.length == 0} >Clear Filters</Button></Box>
            </Box>
          </Grid>
        </Grid>
        </AccordionSummary>}
        <AccordionDetails>

          <FormControl component="fieldset" sx={{ m: 1, width: 190 }}>
            <FormLabel component="legend">Age</FormLabel>

            {/* Age Filter */}
            {ageOptions.map((option, index) => (
              <FormControlLabel
                key={option}
                index={index}
                control={
                  <Radio
                    checked={props.selectedOptions.includes(option)}
                    onClick={() => handleRadioClick(option, 'ageGroupsFilters', index)}
                    name="ageGroupsFilters"
                    value={option}
                    onChange={handleChange} />
                }
                label={option}
              />
            ))}
          </FormControl>

          <FormControl component="fieldset" sx={{ m: 1, width: 190 }}>
            <FormLabel component="legend">Gender</FormLabel>

            {/* Gender Filter */}
            {genderOptions.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Radio
                    checked={props.selectedOptions.includes(option)}
                    onClick={() => handleRadioClick(option, 'genderFilters')}
                    name="genderFilters"
                    value={option}
                    onChange={handleChange} />
                }
                label={option}
              />
            ))}
          </FormControl>

          <FormControl component="fieldset" sx={{ m: 1, width: 190 }}>
            <FormLabel component="legend">Type</FormLabel>

            {/* Type Filter */}
            {typeOptions.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Radio
                    checked={props.selectedOptions.includes(option)}
                    onClick={() => handleRadioClick(option, 'typeFilters')}
                    name="typeFilters"
                    value={option}
                    onChange={handleChange}
                    disabled={ match===true && (option === "GMS" || option === "Private")} />
                }
                label={option}
              />
            ))}
          </FormControl>


          <FormControl component="fieldset" sx={{ m: 1, justifyContent: 'space-between', width: 190 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                name="dateStart"
                disabled={false}
                views={['year', 'month']}
                label="Start Month"
                minDate={last5years}
                maxDate={new Date(dateEnd)}
                value={dateStart}
                onChange={(newValue) => {
                  handleChange({ "name": "dateStart", "value": newValue });
                }}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
              <br />
              <DatePicker
                name="dateEnd"
                disabled={false}
                views={['year', 'month']}
                label="End Month"
                minDate={new Date(dateStart)}
                maxDate={dateEnd}
                value={dateEnd}
                onChange={(newValue) => {
                  handleChange({ "name": "dateEnd", "value": newValue });
                }}
                //disabled={!dateStart}
                renderInput={(params) => <TextField {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </FormControl>
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Stack direction="row" justifyContent="center" sx={{ minWidth: 0 }} spacing={6}>
              <Item>
                <Button variant="outlined" onClick={() => clearFilters()} disabled={combinedFilters.length == 0} >Clear Filters</Button>
              </Item>
              <Item>
                <Button variant="outlined" onClick={props.onClose} >Close Filters</Button>
              </Item>
            </Stack>
          </Box>


        </AccordionDetails>
        </Accordion>
      </Box>

    </MenuPopover>

  );
}

const mapState = state => ({
  filters: state.filters,
  userData: state.user
});

const mapDispatch = ({
  filters: { updateFilter, updateDateFilters, resetFilters },
  antibiotics: { getGeneralAB, promice_8, promice_2,
    promice_3_4, promice_5_6, promice_7 },
  antibioticscomparisons: { getComparisonsAB,
    compare_1,
    compare_2,
    compare_3,
    compare_4,
    compare_5,
    compare_6,
    compare_7,
    pocompare_1,
    pocompare_2,
    pocompare_3,
    pocompare_4,
    pocompare_5,
    pocompare_6,
    pocompare_7, },
  overview: { loadData, getPromise_1, getPromise_2,
     getPromise_3, getPromise_4, getPromise_5,
     getPromise_6, getPromise_7 } }) => ({
    updateFilter: (payload) => updateFilter(payload),
    updateDateFilters: () => updateDateFilters(),
    getGeneralAB: () => getGeneralAB(),
    promice_8: () => promice_8(),
    promice_2: () => promice_2(),
    promice_3_4: () => promice_3_4(),
    promice_5_6: () => promice_5_6(),
    promice_7: () => promice_7(),
    getComparisonsAB: () => getComparisonsAB(),
    compare_1: () => compare_1(),
    compare_2: () => compare_2(),
    compare_3: () => compare_3(),
    compare_4: () => compare_4(),
    compare_5: () => compare_5(),
    compare_6: () => compare_6(),
    compare_7: () => compare_7(),
    pocompare_1: () => pocompare_1(),
    pocompare_2: () => pocompare_2(),
    pocompare_3: () => pocompare_3(),
    pocompare_4: () => pocompare_4(),
    pocompare_5: () => pocompare_5(),
    pocompare_6: () => pocompare_6(),
    pocompare_7: () => pocompare_7(),
    // getPractice:() => getPractice(),
    getPromise_1: () => getPromise_1(),
    getPromise_2: () => getPromise_2(),
    getPromise_3: () => getPromise_3(),
    getPromise_4: () => getPromise_4(),
    getPromise_5: () => getPromise_5(),
    getPromise_6: () => getPromise_6(),
    getPromise_7: () => getPromise_7(),
    loadData: () => loadData(),
    resetFilters: () => resetFilters()
  });


export default connect(mapState, mapDispatch)(popover);
