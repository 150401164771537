import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import account from "../../_mocks_/account";
import { Icon } from "@iconify/react";
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

const dashboard = function DashboardSidebar(props) {
  const { pathname } = useLocation();
  let date = new Date(props.user.lastUpload);
  let formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);

  const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

  const sidebarConfig = [
    {
      title: "Antibiotics Dashboard",
      path:
        props.user.dashboard == 1
          ? "/dashboard/antibiotics"
          : props.user.dashboard == 2
          ? "/dashboard/antibiotics_1"
          : "/dashboard/antibiotics_2",
      enabled: true,
      icon: getIcon("material-symbols:analytics"),
    },
    {
      title: "My Practice",
      path: "/dashboard/practiceoverview",
      enabled: true,
      icon: getIcon("map:doctor"),
    },
    {
      title: "Logout",
      path: "/login",
      enabled: true,
      icon: getIcon("websymbol:logout"),
    },
  ];

  useEffect(() => {
    if (props.isOpenSidebar) {
      props.onCloseSidebar();
    }
  }, [pathname]);

  const handleLogout = async () => {
    await props.logout();
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ px: 4.5, paddingBottom: 3 }}>
        <Box>
          <Typography
            sx={{
              fontSize: 18,
              color: "#22577a",
              display: "inline",
              flexDirection: "row",
            }}
          >
            Last Upload:{" "}
          </Typography>
          <Typography
            sx={{ fontSize: 18, color: "#22577a", display: "inline" }}
          >
            {formattedDate}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {props.user.username}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection
        logoutUser={handleLogout}
        board={props.user.dashboard}
        navConfig={sidebarConfig}
      />

      <Box sx={{ flexGrow: 5 }} />

      <Button
        variant="contained"
        style={{ fontSize: "20px", padding: "20px" }}
        fullWidth
        href="mailto:info@caranetwork.ie"
      >
        Report an issue
      </Button>
      <Box sx={{ flexGrow: 0 }} />
    </Scrollbar>
  );

  return (
    <div>
      <MHidden width="lgUp">
        <Drawer
          open={props.isOpenSidebar}
          onClose={props.onCloseSidebar}
          PaperProps={{
            sx: { width: 1 / 6 },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 1 / 6,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </div>
  );
};

dashboard.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

const mapState = (state) => ({
  user: state.user,
});

const mapDispatch = ({ user: { refreshToken, logout } }) => ({
  refreshToken: () => refreshToken(),
  logout: () => logout(),
});

export default connect(mapState, mapDispatch)(dashboard);
