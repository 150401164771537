import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader, Stack, Typography, TextField, Grid } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
import Input from '@mui/material/Input';
//
import { BaseOptionChart } from '../../charts';
import { alpha, styled } from '@mui/material/styles';
import { shadows } from '@mui/system';
import { connect } from "react-redux";


// ----------------------------------------------------------------------

function AppConversionRates(props) {
const THIS_YEAR = [props.audit2.year1_green, props.audit2.year1_red]

const LAST_YEAR = [props.audit2.year2_green, props.audit2.year2_red]
  const chartOptionsComparisons = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      x: {
          show: true,
          // format: 'dd MMM',
          formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        }
      },
      y: { 
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => 'Average number of antibiotics prescribed:'
        }
      },
    },
    colors: [
      '#26734D',
      '#cc3311',

    ],
    legend: {
      show: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(0) + "%"
      },
    style: {
      fontSize: '20px',
      fontWeight: 'bold',
  },
          },
    plotOptions: {
       pie: {
      customScale: 0.7,
      donut: { labels: { show: false } },
      dataLabels: {
      offset: -20,
    },
    }
    },
  }); 

  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Grid container spacing={4} justifyContent="space-evenly">
        <Grid item sm={12} md={6} lg={5}>
          <ReactApexChart
            type="pie"
            series={THIS_YEAR}
            options={chartOptionsComparisons}
            height={300}
          />
          <Typography variant="subtitle2" align="center">
            This year
          </Typography>
        </Grid>
        <Grid item sm={12} md={6} lg={5}>
          <ReactApexChart
            type="pie"
            series={LAST_YEAR}
            options={chartOptionsComparisons}
            height={300}
          />
          <Typography variant="subtitle2" align="center">
            Last year
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ mx: 5, mt: 10 }}>
        <Box sx={{ mx: 5, mt: 10, border: 1, p: 5 }}>
          <Input
            fullWidth
            multiline
            rows={8}
            defaultValue="Your analysis here"
          />
        </Box>
      </Box>
    </Card>
  );
}

const mapState = (state) => ({
  audit2: state.audit.audit2,
});

const mapDispatch = ({ antibiotics: { getGeneralAB } }) => ({
  getGeneralAB: () => getGeneralAB(),
});

export default connect(mapState, mapDispatch)(AppConversionRates);
