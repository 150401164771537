import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';
import { connect } from "react-redux";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

function DashButton(props) {

  const board = props.board;

  return (
    <RootStyle title="CARA | Dashboard">
      {
        board == 2 ?
        <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Dashboard 2!
              </Typography>
            </motion.div>
            <br />
            <br />
            <Button to="/dashboard/antibiotics_1" size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
      :
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Dashboard 3!
              </Typography>
            </motion.div>
            <br />
            <br />
            <Button to="/dashboard/antibiotics_2" size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </Box>
        </MotionContainer>
      </Container>
      }
    </RootStyle>
  );
}

const mapState = state => ({
  board: state.user.dashboard
});

const mapDispatch = ({
  });

export default connect(mapState, mapDispatch)(DashButton);
