// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Paper,
  Stack,
  Button,
} from "@mui/material";
// components
import Page from "../components/Page";
import {
  AppABPiesComparison,
  ApptotalABCompare,
  ApptotalLabresultsComparison,
  AppLineABComparison,
  AppConsultPrescribeComparison,
  AppAgeOverviewComparison,
  ApptotalDurationComparison,
  ApptypesofABComparison,
  AppResistanceABComparison,
} from "../components/_dashboard/comparison";
import ComparisonsFilters from "../components/_dashboard/comparisonsfilters";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page sx={{ backgroundColor: "#fff" }}>
      <Container maxWidth="xl">
        <Box sx={{ pt: 10 }}></Box>

        <Grid container spacing={12}>
          <Grid item md={12}>
            <ComparisonsFilters />
          </Grid>

          <Grid item xs={12} sm={6} md={12}>
            <Typography
              variant="h2"
              color="secondary"
              style={{ marginTop: "55px" }}
            >
              Antibiotics
            </Typography>
            <br />
            <Button variant="contained" href="">
              Upload new data
            </Button>
            <br></br>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalABCompare />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalDurationComparison />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ApptotalLabresultsComparison />
          </Grid>
        </Grid>

        <Grid container spacing={4} sx={{ borderRadius: 0 }}>
          <Grid item xs={12}>
            <AppConsultPrescribeComparison />
          </Grid>

          <Grid item xs={12} id="antibiotics">
            <Typography variant="h4" color="#22577A">
              Antibiotic Prescriptions
            </Typography>
            <Typography
              variant="subtitle3"
              color="#26734D"
              sx={{ fontWeight: "bold" }}
            >
              Green antibiotics{" "}
            </Typography>
            <Typography variant="subtitle3" color="#22577a">
              are more effective, have fewer side effects and are less likely to
              lead to resistant infections.
            </Typography>
            <br />
            <Typography
              variant="subtitle3"
              color="#cc3311"
              sx={{ fontWeight: "bold" }}
            >
              Red antibiotics{" "}
            </Typography>
            <Typography variant="subtitle3" color="#22577a">
              should not be used in primary care unless absolutely necessary.
            </Typography>
            <AppABPiesComparison />
          </Grid>

          <Grid item xs={12}>
            <ApptypesofABComparison />
          </Grid>

          <Grid item xs={12}>
            <AppResistanceABComparison />
          </Grid>

          <Grid item xs={12}>
            <AppAgeOverviewComparison />
          </Grid>

          <Grid item xs={12} id="duration">
            <AppLineABComparison />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
