import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme, styled } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, Typography, alpha } from "@mui/material";
// utils
import { findNextMultipleOf1000, fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

function AppTypes(props) {
  const [prescXaxis, setPrescXaxis] = useState(0);
  const [otherData, setOtherData] = useState([
    "788",
    "541",
    "414",
    "321",
    "317",
    "42",
    "41",
    "25",
    "21",
    "15",
  ]);
  const [refreshGraph, setRefreshGraph] = useState(false);

  const CHART_DATATypes = [
    {
      data:
        props.icpc_consultation.icpc_counts &&
        props.icpc_consultation.icpc_counts.map((count) => parseInt(count)),
    },
  ];

  const CHART_DATAOtherTypes = [
    {
      data: [...otherData],
    },
  ];

  useEffect(() => {
    if (props.icpc_consultation && props.icpc_consultation !== undefined) {
      setRefreshGraph((prev) => !prev);
    }
  }, [props.icpc_consultation]);

  useEffect(() => {
    if (props.icpc_consultation && props.icpc_consultation.icpc_counts) {
      const maxVal = Math.max(...props.icpc_consultation.icpc_counts);
      const maxX = findNextMultipleOf1000(maxVal + maxVal * 0.2);
      setPrescXaxis(maxX);
    }
  }, [props.icpc_consultation]);

  const allData = [].concat(
    ...CHART_DATATypes.map((obj) => obj.data),
    ...CHART_DATAOtherTypes.map((obj) => obj.data)
  );

  // Find the maximum value from the combined data
  const maxOverall = Math.max(...allData) + 100;
  const chartOptions_TypesBar = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      followCursor: true,
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (val, opt) {
          const total = CHART_DATATypes[0].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        title: {
          formatter: (seriesName) => `Percentage of all consultations: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetX: 35,
    },
    xaxis: {
      categories: props.icpc_consultation.icpc_descriptions
        ? props.icpc_consultation.icpc_descriptions.map((desc) => {
            if (desc.toLowerCase() === "copd") {
              return "COPD";
            }
            return desc.charAt(0).toUpperCase() + desc.slice(1);
          })
        : null,
      show: true,
      min: 0,
      max: prescXaxis,
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          colors: "#808080",
        },
      },
      // position: 'top',
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    stroke: {
      show: false,
    },
  });

  const chartOptions_OtherTypesBar = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      followCursor: true,
      x: {
        show: true,
        // format: 'dd MMM',
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      y: {
        formatter: function (val, opt) {
          const total = CHART_DATATypes[0].data.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const percentage = ((val / total) * 100).toFixed(0);
          return `${percentage}%`;
        },
        title: {
          formatter: (seriesName) => `Percentage of all consultations: `,
        },
      },
    },
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: [alpha("#3E8397", 0.5)],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      formatter: function (val, opt) {
        const label_value = fNumber(val);
        return label_value;
      },
      enabled: true,
      style: {
        colors: ["#708090"],
      },
      offsetX: 35,
    },
    xaxis: {
      categories: props.icpc_consultation.icpc_descriptions
        ? props.icpc_consultation.icpc_descriptions.map((desc) => {
            if (desc.toLowerCase() === "copd") {
              return "COPD";
            }
            return desc.charAt(0).toUpperCase() + desc.slice(1);
          })
        : null,
      show: true,
      min: 0,
      max: prescXaxis,
      tickAmount: 5,
      labels: {
        show: true,
        style: {
          colors: "#808080",
        },
      },
      // position: 'top',
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
      },
    },
    stroke: {
      show: false,
    },
  });
  const chartOptions_Middle = merge(BaseOptionChart(), {
    grid: {
      borderColor: "transparent",
      row: {
        colors: ["transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    colors: ["#3E8397"],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
        columnWidth: "60%",
        distributed: false,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#ffffff"],
      },
      position: "top",
    },
    xaxis: {
      categories: [
        "Uncoded consultations",
        'medical examination/health evaluation complete"',
        "diabetes non-insulin dependent",
        "Muscoloskeletal",
        "Digestive System",
        "Cardiovascular",
        "Family Planning",
        "Social Problems",
        "Urological",
        "Male Genitalia",
      ],
      labels: {
        show: false,
        style: {
          colors: "#808080",
        },
        align: "center", // Center-align the labels
      },
      // position: 'top',
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#22577A",
        },
        align: "center", // Center-align the labels
      },
    },
  });
  return (
    <Card sx={{ boxShadow: "none", mt: 4 }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
        mt={1}
      >
        <Box dir="ltr" width="40%">
          <ReactApexChart
            type="bar"
            series={CHART_DATATypes}
            options={chartOptions_TypesBar}
            height={440}
            key={refreshGraph}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Your practice
          </Typography>
        </Box>
        {/* <Box dir="ltr" width="8%"> */}
        {/* This invisible chart creates the space */}
        {/* <ReactApexChart type="bar" series={[]} options={chartOptions_Middle} height={420} width={150}/> */}
        {/* </Box> */}
        <Box dir="ltr" width="40%">
          <ReactApexChart
            type="bar"
            series={CHART_DATAOtherTypes}
            options={chartOptions_OtherTypesBar}
            height={440}
          />
          <Typography
            variant="caption"
            color="grey"
            display="block"
            align="center"
          >
            Filtered practices
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
}
import { connect } from "react-redux";
import { useEffect, useState } from "react";
const mapState = (state) => ({
  prescriptions: state.overview.prescriptions,
  otherprescriptions: state.antibioticscomparisons.prescriptions,
  icpc_consultation: state.overview.icpc_consultation,
});

const mapDispatch = ({
  overview: { loadData },
  antibioticscomparisons: { getComparisonsAB },
}) => ({
  loadData: () => loadData(),
  getComparisonsAB: () => getComparisonsAB(),
});

export default connect(mapState, mapDispatch)(AppTypes);
