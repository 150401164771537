import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Grid,
  Button,
  Chip,
  Paper,
} from "@mui/material";
// components
import { MHidden } from "../../components/@material-extend";
import Searchbar from "./Searchbar";
import FilterPopover from "./FilterPopover";
import { borders } from "@mui/system";
import { useRef, useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { connect } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import Label from "src/components/Label";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - 100%/6)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

function DashboardNavbar(props, { onOpenSidebar }) {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [combinedFilters, setCombinedFilters] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const category = "Dashboard " + props.user.dashboard;
  const dashNo = props.user.dashboard;
  const [expanded, setExpanded] = useState(false);
  const [accordionExpand, setAccordionExpand] = useState(false);
  const [fullExpand, setFullExpand] = useState(false);
  const [iconFlipped, setIconFlipped] = useState(false);
  const [isAudit, setIsAudit] = useState(false);
  const [isMyPractice, setIsMyPractice] = useState(false);
  const [ageGroupsFilters, setAgeGroupsFilters] = useState([]);
  const [genderFilters, setGenderFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState([]);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();

  useEffect(() => {
    if (location.pathname.includes("audit")) {
      setIsAudit(true);
    } else if (location.pathname.includes("practiceoverview")) {
      setIsMyPractice(true);
    } else {
      setIsMyPractice(false);
      setIsAudit(false);
    }
  }, [location.pathname]);

  // useEffect(() => {
  //   if (location.pathname.includes('practiceoverview')) {
  //     setIsMyPractice(true);
  //   } else {
  //     setIsMyPractice(false);
  //   }
  // }, [location.pathname]);

  const openPopover = () => {
    ReactGA.event({
      category: category,
      action: "Filter Click",
      label: "Antibiotic Dashboard " + dashNo,
      value: parseInt(dashNo),
    });
    setFullExpand(true);
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setFullExpand(false);
    setAccordionExpand(false);
    setIsPopoverOpen(false);
  };

  const openAudit = () => {
    ReactGA.event({
      category: category,
      action: "Audit Click",
      label: "Antibiotic Dashboard " + dashNo,
      value: parseInt(dashNo),
    });
    navigate("/dashboard/antibiotics/audit");
    setIsAudit(true);
  };

  const openCompair = () => {
    ReactGA.event({
      category: category,
      action: "Compair Click",
      label: "Antibiotic Dashboard " + dashNo,
      value: parseInt(dashNo),
    });

    props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4();
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();

    if (location.pathname.includes("practiceoverview")){
      navigate("/dashboard/practiceoverview/practicecomparisons");
    }
    else {
      navigate("/dashboard/antibiotics/comparison");
    }
  };

  const handleAccordianChange = () => {
    setAccordionExpand(!accordionExpand);
    setExpanded(!expanded);
    setIconFlipped(!iconFlipped);
    setIsPopoverOpen(!isPopoverOpen);
  };

  const handleClearFilters = async () => {
    setSelectedOptions([]);
    setAgeGroupsFilters([]);
    setGenderFilters([]);
    setTypeFilters([]);
    setDateStart(props.user.previousYear);
    setDateEnd(props.user.lastUpload);
    await props.resetFilters();
    props.updateDateFilters();
    updateFilters();
  };

  let updateFilters = async () => {
    await props.getGeneralAB();
    props.promice_8();
    props.promice_2();
    props.promice_3_4();
    props.promice_5_6();
    props.promice_7();
    props.getPromise_1();
    props.getPromise_2();
    props.getPromise_3();
    props.getPromise_4();
    props.getPromise_5();
    props.getPromise_6();
    props.getPromise_7();
    props.compare_1();
    props.compare_2();
    props.compare_3();
    props.compare_4();
    props.compare_5();
    props.compare_6();
    props.compare_7();
    props.pocompare_1();
    props.pocompare_2();
    props.pocompare_3();
    props.pocompare_4();
    props.pocompare_5();
    props.pocompare_6();
    props.pocompare_7();
    props.loadData();
    props.getComparisonsAB();
  };

  return (
    <RootStyle>
      {!isAudit && (
        <ToolbarStyle>
          <Box sx={{ mx: 1, borderRadius: 0 }} dir="ltr" />
          <Grid container spacing={12}>
            <Grid item xs={2} sm={3} md={4}>
              <Button
                style={{ fontSize: "20px" }}
                variant="contained"
                onClick={openPopover}
                fullWidth
              >
                Filter your data
              </Button>
              {
                <FilterPopover
                  isOpen={isPopoverOpen}
                  onClose={closePopover}
                  setCombinedFilters={setCombinedFilters}
                  fullExpand={fullExpand}
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  combinedFilters={combinedFilters}
                  setAgeGroupsFilters={setAgeGroupsFilters}
                  ageGroupsFilters={ageGroupsFilters}
                  setGenderFilters={setGenderFilters}
                  genderFilters={genderFilters}
                  setTypeFilters={setTypeFilters}
                  typeFilters={typeFilters}
                  setDateStart={setDateStart}
                  dateStart={dateStart}
                  setDateEnd={setDateEnd}
                  dateEnd={dateEnd}
                />
              }
            </Grid>
            <Grid item xs={2} sm={3} md={4}>
              <Button
                style={{ fontSize: "20px" }}
                variant="contained"
                onClick={openCompair}
                fullWidth
              >
                Compare your data
              </Button>
            </Grid>
            {!isMyPractice && (
              <Grid item xs={2} sm={3} md={4}>
                <Button
                  style={{ fontSize: "20px" }}
                  variant="contained"
                  onClick={openAudit}
                  fullWidth
                >
                  Audit
                </Button>
              </Grid>
            )}
          </Grid>
        </ToolbarStyle>
      )}
      {!isAudit && (
        <Box
          sx={{
            position: "fixed",
            top: 83,
            zIndex: 6,
            width: "95%",
            left: 38,
          }}
        >
          <Paper
            sx={{
              width: "100%",
              height: "75px",
              padding: "5px",
              border: "1px solid rgba(145, 158, 171, 0.24)",
              borderRadius: "8px",
              minHeight: "48px",
              display: "flex",
              alignItems: "center",
            }}
            elevation={2}
          >
            <Grid item md={2}>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#22577a",
                  textAlign: "center",
                }}
              >
                Filters applied:
              </Typography>
            </Grid>
            <Grid item md={6.88} style={{ textAlign: "center" }}>
              {combinedFilters.length > 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 0.5,
                  }}
                >
                  {combinedFilters.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    gap: 0.5,
                  }}
                >
                  No filters Selected
                </Box>
              )}
            </Grid>
            <Grid item md={2}>
              <Button
                variant="outlined"
                disabled={combinedFilters.length === 0}
                onClick={handleClearFilters}
              >
                Clear Selection
              </Button>
            </Grid>
            <Grid
              item
              md={1}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ExpandMoreIcon
                style={{
                  color: "#637381",
                  cursor: "pointer",
                  transform: iconFlipped ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease-in-out",
                }}
                onClick={handleAccordianChange}
              />
              {accordionExpand && (
                <FilterPopover
                  isOpen={isPopoverOpen}
                  onClose={closePopover}
                  setCombinedFilters={setCombinedFilters}
                  combinedFilters={combinedFilters}
                  fullExpand={false}
                  setSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedOptions}
                  setAgeGroupsFilters={setAgeGroupsFilters}
                  ageGroupsFilters={ageGroupsFilters}
                  setGenderFilters={setGenderFilters}
                  genderFilters={genderFilters}
                  setTypeFilters={setTypeFilters}
                  typeFilters={typeFilters}
                  setDateStart={setDateStart}
                  dateStart={dateStart}
                  setDateEnd={setDateEnd}
                  dateEnd={dateEnd}
                />
              )}
            </Grid>
          </Paper>
        </Box>
      )}
    </RootStyle>
  );
}

const mapState = (state) => ({
  user: state.user,
  filters: state.filters,
});

const mapDispatch = ({
  user: { login, addDashboard },
  filters: { updateFilter, updateDateFilters, resetFilters },
  antibiotics: {
    getGeneralAB,
    promice_8,
    promice_2,
    promice_3_4,
    promice_5_6,
    promice_7,
  },
  antibioticscomparisons: {
    getComparisonsAB,
    compare_1,
    compare_2,
    compare_3,
    compare_4,
    compare_5,
    compare_6,
    compare_7,
    pocompare_1,
    pocompare_2,
    pocompare_3,
    pocompare_4,
    pocompare_5,
    pocompare_6,
    pocompare_7,
  },
  overview: {
    loadData,
    getPromise_1,
    getPromise_2,
    getPromise_3,
    getPromise_4,
    getPromise_5,
    getPromise_6,
    getPromise_7,
  },
}) => ({
  login: (payload) => login(payload),
  addDashboard: (payload) => addDashboard(payload),
  resetFilters: () => resetFilters(),
  updateFilter: (payload) => updateFilter(payload),
  updateDateFilters: () => updateDateFilters(),
  getGeneralAB: () => getGeneralAB(),
  promice_8: () => promice_8(),
  promice_2: () => promice_2(),
  promice_3_4: () => promice_3_4(),
  promice_5_6: () => promice_5_6(),
  promice_7: () => promice_7(),
  getComparisonsAB: () => getComparisonsAB(),
  compare_1: () => compare_1(),
  compare_2: () => compare_2(),
  compare_3: () => compare_3(),
  compare_4: () => compare_4(),
  compare_5: () => compare_5(),
  compare_6: () => compare_6(),
  compare_7: () => compare_7(),
  pocompare_1: () => pocompare_1(),
  pocompare_2: () => pocompare_2(),
  pocompare_3: () => pocompare_3(),
  pocompare_4: () => pocompare_4(),
  pocompare_5: () => pocompare_5(),
  pocompare_6: () => pocompare_6(),
  pocompare_7: () => pocompare_7(),
  getPromise_1: () => getPromise_1(),
  getPromise_2: () => getPromise_2(),
  getPromise_3: () => getPromise_3(),
  getPromise_4: () => getPromise_4(),
  getPromise_5: () => getPromise_5(),
  getPromise_6: () => getPromise_6(),
  getPromise_7: () => getPromise_7(),
  loadData: () => loadData(),
});

export default connect(mapState, mapDispatch)(DashboardNavbar);
